
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  

  
  import * as viewerApp from '/home/builduser/work/2064253189211c70/packages/viewer/src/viewer.app.ts';
    var importedApp = viewerApp;


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://83bbe6dcc3ea4b44ac87b8ce1f0bc27c@sentry.wixpress.com/2348',
      id: '83bbe6dcc3ea4b44ac87b8ce1f0bc27c',
      projectName: 'viewer',
      teamName: 'events',
      errorMonitor: true,
    };

  var experimentsConfig = {"scopes":["viewer-apps-14ce28f7-7eb0-3745-22f8-074b0e2401fb"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/2064253189211c70/packages/viewer/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/2064253189211c70/packages/viewer/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "wix-seating-viewer",
    biConfig: null,
    appName: "Seating",
    appDefinitionId: "64e6bcf3-b6e2-456a-81f9-b0cfbdd5c2c3",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: null,
          wrap: null,
          exports: null,
          widgetType: "WIDGET_OUT_OF_IFRAME",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":["viewer-apps-14ce28f7-7eb0-3745-22f8-074b0e2401fb"],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/2064253189211c70/packages/viewer/src/components/Widget/controller.ts",
          appName: "Seating",
          appDefinitionId: "64e6bcf3-b6e2-456a-81f9-b0cfbdd5c2c3",
          projectName: "wix-seating-viewer",
          componentName: "Widget",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "2c546300-e889-4a8b-a968-ebf2cdc95b0a" }],
    false);

    export const createControllers = _createControllers
